import React, { useRef, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CircularProgress, Typography, Box } from "@mui/material";

// Registering components
ChartJS.register(ArcElement, Tooltip, Legend);

export function MaterialPieChart({ materialStats, isLoading }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = chartRef.current;
      chart.canvas.style.width = "100%";
      chart.canvas.style.height = "auto";
      chart.canvas.style.aspectRatio = "1 / 1";
      chart.resize();
    }
  }, []);

  // Aggregate data by material type
  const aggregateDataByMaterial = (data) => {
    const aggregatedData = {};
    if (!data) {
      return aggregatedData;
    }
    data.forEach((stat) => {
      if (!aggregatedData[stat.material]) {
        aggregatedData[stat.material] = 0;
      }
      aggregatedData[stat.material] += parseInt(stat.total_units, 10); // Aggregate by total_units
    });

    return aggregatedData;
  };

  const aggregatedData = aggregateDataByMaterial(materialStats);

  // Predefined color palette to match the color scheme of your application
  const colorPalette = [
    "#003f5c",
    "#2f4b7c",
    "#665191",
    "#a05195",
    "#d45087",
    "#f95d6a",
    "#ff7c43",
    "#ffa600",
    "#636363",
    "#bc5090",
    "#58508d",
    "#ff6361",
    "#bc5090",
    "#ffa600",
    "#003f5c",
    "#8e7dbe",
    "#6a51a3",
    "#cb55a5",
    "#d45087",
    "#bb3b6d",
    "#833c7e",
    "#ff7c43",
    "#f6701f",
    "#bc5090",
    "#f85e7d",
    "#3f7f93",
    "#67a4dc",
    "#45aeb3",
    "#f7b8b3",
    "#fe8282",
    "#ec8a90",
    "#c94a53",
    "#d84436",
    "#ed681d",
    "#edae2b",
    "#ff7c43",
    "#83b5d7",
    "#89d8d3",
    "#92e595",
    "#beea72",
  ];

  // Prepare data for the Pie chart
  const pieData = {
    labels: Object.keys(aggregatedData), // Material types
    datasets: [
      {
        data: Object.values(aggregatedData), // Corresponding total_units
        backgroundColor: colorPalette.slice(
          0,
          Object.keys(aggregatedData).length
        ), // Use colors from the palette
      },
    ],
  };

  const hasData = materialStats && materialStats.length > 0;

  if (isLoading) {
    return (
      <Box
        className="material-pie-chart-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!hasData) {
    return (
      <Box
        className="material-pie-chart-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={400}
      >
        <Typography variant="h6" color="text.secondary">
          No data available
        </Typography>
      </Box>
    );
  }

  return (
    <div className="material-pie-chart-container">
      <Pie
        ref={chartRef}
        data={pieData}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
              align: "center",
              labels: {
                boxWidth: 15,
                padding: 10,
                font: {
                  size: 12,
                },
              },
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  let label = tooltipItem.label || "";
                  if (label) {
                    label += ": ";
                  }
                  label += tooltipItem.raw.toLocaleString();
                  return label;
                },
              },
            },
          },
        }}
      />
    </div>
  );
}

// Update the style block
const styles = `
  <style>
    .material-pie-chart-container {
		align-items: center;
		display: flex;
		justify-content: center;
		width: 100%;
    }
    .material-pie-chart-container canvas {
		height: calc(100vh - 425px) !important;
		margin: 0 auto;
		max-height: 500px !important;
		max-width: 889px !important;
		width: 100%;
    }
    @media (max-width: 768px) {
      .material-pie-chart-container canvas {
        max-width: 300px !important;
        max-height: 300px !important;
      }
    }
  </style>
`;

// Inject the styles into the document head
if (typeof document !== "undefined") {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = styles;
  document.head.appendChild(styleElement);
}
