import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { req } from "utils/api";
import { CustomerAccount } from "../../components/settings/CustomerAccount";
import { CustomerSecurity } from "../../components/settings/CustomerSecurity";
import { CustomerTeam } from "../../components/settings/CustomerTeam";
import { SnackbarNotification } from "../../components/SnackbarNotification";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import GroupsIcon from "@mui/icons-material/Groups";
import LockIconOutlinedIcon from "@mui/icons-material/LockOutlined";
import CircularProgress from "@mui/material/CircularProgress"; // Import MUI CircularProgress

export function CustomerSettings() {
  const location = useLocation();
  const [accountData, setAccountData] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState("account");
  const [loading, setLoading] = useState(true);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const getQueryStringValue = (key) => {
    return new URLSearchParams(location.search).get(key);
  };

  const handleDrawerContent = (option) => () => {
    setDrawerContent(option);
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleUpdateAccount = async (data) => {
    // console.log("Update Account: ", data); // Debugging

    const url = "customer/update_account";
    try {
      const result = await req("POST", url, data);
      if (result.success) {
        setSnackbarMessage("Account updated successfully.");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(`Failed to update account: ${result.message}`);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while updating the account.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleUpdateUser = async (data) => {
    // console.log("Update User: ", data); // Debugging

    const url = "customer/update_user";
    try {
      const result = await req("POST", url, data);
      if (result.success) {
        setSnackbarMessage("User updated successfully.");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(`Failed to update user: ${result.message}`);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while updating the user.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleChangeUserRole = (role) => {
    // setUserRole(role);
    // console.log("Change user role team member with id: ", role);
  };

  const handleCreateTeamMember = async (data) => {
    // console.log("Create a team member with data: ", data);

    const url = "customer/create_team_member";
    try {
      const result = await req("POST", url, data);
      if (result.success) {
        setSnackbarMessage("Team Member created successfully.");
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage(`Failed to create Team Member: ${result.message}`);
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while creating a Team Member.");
      setSnackbarSeverity("error");
    } finally {
      setOpenSnackbar(true);
    }
  };

  const handleDeleteTeamMember = (id) => (event) => {
    event.preventDefault();
    console.log("Delete team member with id: ", id);
  };

  useEffect(() => {
    fetchAccount();
    // fetchUser();
    setDrawerContent(getQueryStringValue("n") || "account");
    setLoading(false);
  }, []);

  async function fetchAccount() {
    try {
      const response = await req("get", "customer/get_account");
      const data = response.data;
      // console.log("Account Data: ", data); // Debugging
      if (data) {
        setAccountData(data);
      }
    } catch (error) {
      console.log("There was an error fetching account data:", error);
      setLoading(false); // Ensure loading is turned off even if there's an error
    }
  }

  return (
    <>
      <div className="flex flex-auto flex-col relative">
        <div className="flex w-full min-w-0 flex-col sm:absolute sm:inset-0 sm:overflow-hidden">
          <div className="flex-auto relative sm:overflow-hidden sm:h-full">
            <div className="relative h-full overflow-auto">
              <div className="flex w-full flex-auto justify-center relative sm:p-6 md:p-8">
                <div className="flex w-full flex-auto flex-col max-w-6xl sm:overflow-hidden sm:rounded-lg sm:shadow-lg">
                  <div
                    id="settings"
                    className="flex flex-auto flex-col w-full relative"
                  >
                    {loading ? (
                      <div className="flex items-center justify-center h-full">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="flex w-full min-w-0 flex-col sm:inset-0 sm:overflow-hidden">
                        <div className="mat-drawer-container flex-auto sm:h-full min-h-120">
                          <div
                            tabIndex="-1"
                            className="mat-drawer dark:bg-gray-900 sm:w-96 mat-drawer-side mat-drawer-opened"
                            style={{ transform: "none", visibility: "visible" }}
                          >
                            <div className="mat-drawer-inner-container">
                              <div className="m-8 mr-6 flex items-center justify-between sm:my-10">
                                <div className="text-4xl font-extrabold leading-none tracking-tight">
                                  Settings
                                </div>
                                <div className="lg:hidden">
                                  <button className="mdc-icon-button mat-mdc-icon-button mat-unthemed mat-mdc-button-base">
                                    <span className="mat-mdc-button-persistent-ripple mdc-icon-button__ripple"></span>
                                    <mat-icon
                                      role="img"
                                      className="mat-icon  mat-icon-no-color"
                                      aria-hidden="true"
                                      data-mat-icon-type="svg"
                                      data-mat-icon-name="x-mark"
                                      data-mat-icon-namespace="heroicons_outline"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                        fit=""
                                        height="100%"
                                        width="100%"
                                        preserveAspectRatio="xMidYMid meet"
                                        focusable="false"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M6 18L18 6M6 6l12 12"
                                        ></path>
                                      </svg>
                                    </mat-icon>
                                    <span className="mat-mdc-focus-indicator"></span>
                                    <span className="mat-mdc-button-touch-target"></span>
                                  </button>
                                </div>
                              </div>
                              <div className="flex flex-col divide-y border-b border-t">
                                <div
                                  className={`flex cursor-pointer px-8 py-5 ${drawerContent === "account" ? "bg-primary-50 dark:bg-hover" : "dark:hover:bg-hover hover:bg-gray-100"}`}
                                  onClick={handleDrawerContent("account")}
                                >
                                  <AccountCircleOutlinedIcon
                                    role="img"
                                    className={`mat-icon  mat-icon-no-color ${drawerContent === "account" ? "text-primary dark:text-primary-500" : "text-hint"}`}
                                    aria-hidden="true"
                                  />
                                  <div className="ml-3">
                                    <div
                                      className={`font-medium leading-6 ${drawerContent === "account" ? "text-primary dark:text-primary-500" : ""}`}
                                    >
                                      Account
                                    </div>
                                    <div className="text-secondary mt-0.5">
                                      Manage your account profile and contact
                                      information
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`flex cursor-pointer px-8 py-5 ${drawerContent === "security" ? "bg-primary-50 dark:bg-hover" : "dark:hover:bg-hover hover:bg-gray-100"}`}
                                  onClick={handleDrawerContent("security")}
                                >
                                  <LockIconOutlinedIcon
                                    role="img"
                                    className={`mat-icon  mat-icon-no-color ${drawerContent === "security" ? "text-primary dark:text-primary-500" : "text-hint"}`}
                                    aria-hidden="true"
                                  />
                                  <div className="ml-3">
                                    <div
                                      className={`font-medium leading-6 ${drawerContent === "security" ? "text-primary dark:text-primary-500" : ""}`}
                                    >
                                      Security
                                    </div>
                                    <div className="text-secondary mt-0.5">
                                      Manage your personal username, email and
                                      password
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`flex cursor-pointer px-8 py-5 ${drawerContent === "team" ? "bg-primary-50 dark:bg-hover" : "dark:hover:bg-hover hover:bg-gray-100"}`}
                                  onClick={handleDrawerContent("team")}
                                >
                                  <GroupsIcon
                                    role="img"
                                    className={`mat-icon  mat-icon-no-color ${drawerContent === "team" ? "text-primary dark:text-primary-500" : "text-hint"}`}
                                    aria-hidden="true"
                                  />
                                  <div className="ml-3">
                                    <div
                                      className={`font-medium leading-6 ${drawerContent === "team" ? "text-primary dark:text-primary-500" : ""}`}
                                    >
                                      Team
                                    </div>
                                    <div className="text-secondary mt-0.5">
                                      Manage your existing team
                                      {/* and change roles/permissions */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="mat-drawer-content flex flex-col bg-white"
                            style={{ marginLeft: "384px" }}
                          >
                            {drawerContent === "account" && (
                              <CustomerAccount
                                data={accountData}
                                handleDrawerToggle={handleDrawerToggle}
                                handleUpdateAccount={handleUpdateAccount}
                              />
                            )}
                            {drawerContent === "security" && (
                              <CustomerSecurity
                                data={accountData}
                                handleDrawerToggle={handleDrawerToggle}
                                handleUpdateUser={handleUpdateUser}
                              />
                            )}
                            {drawerContent === "team" && (
                              <CustomerTeam
                                data={accountData}
                                handleDrawerToggle={handleDrawerToggle}
                                handleChangeUserRole={handleChangeUserRole}
                                handleCreateTeamMember={handleCreateTeamMember}
                                handleDeleteTeamMember={handleDeleteTeamMember}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackbarNotification
        open={openSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </>
  );
}
