// 1. React Imports:
import { useEffect, useState } from "react";

// 2. Third-Party Library Imports:
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";

// 3. Component Imports:
import { LocationManagement } from "./LocationManagement";
import { TeamManagement } from "./TeamManagement";

// 7. Assets:
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import SubjectIcon from "@mui/icons-material/Subject";

// --------------------------------------------------------------

export function CreateUpdateCustomer({
  customer = [],
  closeDataDrawer,
  createCustomer,
  updateCustomer,
  deleteAccount,
  addLocation,
  removeLocation,
  createTeamMember,
  deleteTeamMember,
}) {
  /*-------------------------------------
    State Variables and Hooks
  -------------------------------------*/
  const [loading, setLoading] = useState(true);

  const [customerId, setCustomerId] = useState(null);
  const [customerName, setCustomerName] = useState(
    customer?.account_name || ""
  );
  const [customerEmail, setCustomerEmail] = useState(
    customer?.primary_user_email || ""
  );
  const [customerDescription, setCustomerDescription] = useState(
    customer?.description || ""
  );
  const [locations, setLocations] = useState(customer?.account_locations || []); // Location Management
  const [additionalLogins, setAdditionalLogins] = useState(
    customer?.additional_logins || []
  ); // Team Management

  const [formErrors, setFormErrors] = useState({});
  const [formProcessing, setFormProcessing] = useState(false);

  /*--------------------------------------
    Utility Functions
  -------------------------------------*/

  const validateForm = () => {
    let formErrors = {};

    if (!customerName) {
      formErrors.name = "Customer name is required.";
    }
    if (!customerEmail) {
      formErrors.email = "Customer email is required.";
    } else if (!/\S+@\S+\.\S+/.test(customerEmail)) {
      formErrors.email = "Customer email is invalid.";
    }

    setFormErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  /*-------------------------------------
		Event Handlers
	-------------------------------------*/

  const handleClickCreateAccount = async () => {
    if (!validateForm()) return;

    const data = {
      name: customerName,
      email: customerEmail,
      description: customerDescription,
    };

    try {
      setFormProcessing(true);
      const response = await createCustomer(data);

      if (response) {
        customer.account_id = response.accountId;
      }
    } catch (error) {
      console.error("Error creating customer:", error);
    } finally {
      setFormProcessing(false);
    }
  };

  const handleClickSaveAccount = async () => {
    if (!validateForm()) return;

    const postData = {
      account_id: customer?.account_id,
      user_id: customer?.primary_user_id,
      email: customerEmail,
      name: customerName,
      description: customerDescription,
    };

    // await req("post", "admin/update_customer", postData);

    setFormProcessing(true);
    await updateCustomer(postData);
    setFormProcessing(false);
  };

  // const handleSaveAccount = async () => {
  //   if (!customerName || !customerEmail) {
  //     alert("Please fill in all required fields.");
  //     return;
  //   }

  //   setLoading(true);

  //   try {
  //     const data = {
  //       account_id: customer?.account_id,
  //       user_id: customer?.user_id,
  //       email,
  //       name,
  //       description,
  //     };

  //     await req("post", "admin/update_customer", data);
  //     reloadCustomers();
  //     closeDataDrawer();
  //   } catch (error) {
  //     console.error("Error updating customer:", error);
  //     alert("Failed to update customer.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleClickDeleteAccount = () => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      deleteAccount(customerId);
    }
  };

  const handleUpdateLocations = (updatedLocations) => {
    setLocations(updatedLocations);
  };

  const handleUpdateAdditionalLogins = (updatedLogins) => {
    setAdditionalLogins(updatedLogins);
  };

  /*-------------------------------------
		Effects
	-------------------------------------*/

  useEffect(() => {
    setCustomerId(customer?.account_id || "");
    setLoading(false);
  }, [customer]);

  console.log("Customer", customer);
  // --------------------------------------------------------------

  return (
    <>
      <div className="relative w-full px-8 sm:px-12">
        <div className="mx-auto flex w-full max-w-3xl items-start justify-between py-6">
          <div>
            <div className="text-4xl font-bold">
              {customerId ? "Edit Customer" : "Add New Customer"}
            </div>
          </div>
          <div className="flex items-center mt-1 ml-auto">
            <IconButton onClick={closeDataDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="relative flex flex-auto flex-col items-center p-6 py-0 sm:p-12 sm:py-0">
        <div className="w-full max-w-3xl border-t h-full">
          {loading ? (
            <div className="flex w-full h-full justify-center items-center">
              <CircularProgress />
            </div>
          ) : (
            <form noValidate className="flex flex-auto flex-col h-full">
              <div className="mt-8">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_name">Customer Name</label>
                  <TextField
                    id="customer_name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    error={!!formErrors.name}
                    helperText={formErrors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_email">Customer Email</label>
                  <TextField
                    id="customer_email"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-auto flex-col">
                  <label htmlFor="customer_description">Notes</label>
                  <TextField
                    id="customer_description"
                    value={customerDescription}
                    onChange={(e) => setCustomerDescription(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{ display: "flex", alignSelf: "flex-start" }}
                        >
                          <SubjectIcon style={{ alignSelf: "flex-start" }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={2}
                  />
                </div>
              </div>
              {customerId && (
                <>
                  <LocationManagement
                    account_id={customerId}
                    account_locations={locations}
                    addLocation={addLocation}
                    removeLocation={removeLocation}
                    updateLocations={handleUpdateLocations}
                  />
                  <TeamManagement
                    account_id={customerId}
                    additionalLogins={additionalLogins}
                    createTeamMember={createTeamMember}
                    deleteTeamMember={deleteTeamMember}
                    updateAdditionalLogins={handleUpdateAdditionalLogins}
                  />
                </>
              )}
              <div className="mt-auto">
                <div className="-mx-6 mt-10 flex items-center border-t bg-gray-50 py-4 pl-1 pr-4 dark:bg-transparent sm:-mx-12 sm:pl-7 sm:pr-12">
                  {customerId && (
                    <Button
                      className="mdc-button mat-mdc-button mat-warn mat-mdc-button-base"
                      onClick={handleClickDeleteAccount}
                      disabled={formProcessing}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    className="ml-auto mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base"
                    onClick={closeDataDrawer}
                    disabled={formProcessing}
                  >
                    Cancel
                  </Button>
                  {customerId ? (
                    <Button
                      variant="contained"
                      className="ml-2 mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                      onClick={handleClickSaveAccount}
                      disabled={formProcessing}
                      disableElevation
                    >
                      {formProcessing ? "Saving..." : "Save"}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="ml-2 mdc-button mat-mdc-button mdc-button--unelevated mat-mdc-unelevated-button mat-primary mat-mdc-button-base"
                      onClick={handleClickCreateAccount}
                      disabled={formProcessing}
                      disableElevation
                    >
                      {formProcessing ? "Creating..." : "Create"}
                    </Button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
