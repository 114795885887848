import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import jwtDecode from "jwt-decode";
import { req } from "utils/api";

const AuthContext = createContext();
const STORE = "_tomra_auth";

// Move these functions outside of AuthProvider




export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: null,
    refreshToken: null,
    user: null,
    isAuthenticated: false,
    loading: true,
  });

  const isTokenExpired = (token) => {
    if (!token) return true;
    const { exp } = jwtDecode(token);
    return Date.now() >= exp * 1000;
  };

  const checkAndRefreshToken = async () => {
    //console.log("Checking and refreshing token");
    const storedAuthData = localStorage.getItem(STORE);
    //console.log("Stored auth data:", storedAuthData);
    if (!storedAuthData) {
      console.log("No stored auth data found");
      setAuthState({
        token: null,
        refreshToken: null,
        user: null,
        isAuthenticated: false,
        loading: false,
      });
      return false;
    }

    try {
      const renewedAuthData = JSON.parse(storedAuthData);
      const { token, refreshToken, tokenExpiresAt } = renewedAuthData;
      //console.log("Token expires at:", new Date(renewedAuthData.tokenExpiresAt));
      const isExpired = new Date(renewedAuthData.tokenExpiresAt) <= new Date();

      if (isExpired) {
        if (refreshToken) {
          //console.log("Attempting to refresh token");
          await refreshAuthToken();
        } else {
          console.log("No refresh token available");
          throw new Error("No refresh token available");
        }
      } else {
        //console.log("Token is not expired");
        let newAuthState = {
          isAuthenticated: true,
          loading: false,
          user: renewedAuthData.user,
          token: renewedAuthData.token,
          refreshToken: renewedAuthData.refreshToken,
        };
        console.log("New auth state:", newAuthState);

        setAuthState(newAuthState);
      }
      return true;
    } catch (error) {
      console.error("Token refresh failed:", error);
      localStorage.removeItem(STORE);
      setAuthState({
        token: null,
        refreshToken: null,
        user: null,
        isAuthenticated: false,
        loading: false,
      });
      return false;
    }
  };

  useEffect(() => {
    checkAndRefreshToken();
  }, []);

  const login = async (email, password) => {
    try {
      const loginUrl = "login";
      const result = await req("POST", loginUrl, { email, password });

      if (!result.success) {
        throw new Error(
          result.message || "Login failed. Please check your credentials."
        );
      }

      const {
        token,
        refreshToken,
        tokenExpiresAt,
        refreshTokenExpiresAt,
        ...user
      } = result;

      const authData = {
        token,
        refreshToken, // Make sure this is included
        user,
        isAuthenticated: true,
        tokenExpiresAt,
        refreshTokenExpiresAt,
      };

      console.log("Storing auth data:", authData); // Log the data being stored
      localStorage.setItem(STORE, JSON.stringify(authData));
      setAuthState({
        token,
        refreshToken,
        user,
        isAuthenticated: true,
        loading: false,
      });

      return result;
    } catch (error) {
      console.error("Login error", error);
      throw new Error(error.message || "An error occurred during login.");
    }
  };

  const logout = () => {
    localStorage.removeItem(STORE);
    setAuthState({
      token: null,
      refreshToken: null,
      user: null,
      isAuthenticated: false,
      loading: false,
    });
    window.location.href = "/login";
  };

  const refreshAuthToken = async () => {
    console.log("Attempting to refresh auth token");
    try {
      const storedAuthData = localStorage.getItem(STORE);
      console.log("Retrieved stored auth data:", storedAuthData);

      if (!storedAuthData) {
        console.log("No stored auth data found, logging out");
        logout();
        return;
      }

      const parsedAuthData = JSON.parse(storedAuthData);
      //console.log("Parsed auth data:", parsedAuthData);

      if (!parsedAuthData.refreshToken) {
        console.log(
          "No refresh token found in stored data, attempting to use token"
        );
        if (parsedAuthData.token) {
          // If there's a token but no refresh token, try to use the token
          setAuthState({
            ...parsedAuthData,
            isAuthenticated: true,
            loading: false,
          });
          return;
        }
        console.log("No token found either, logging out");
        logout();
        return;
      }

      console.log("Sending refresh token request");
      const refreshUrl = "refresh_token";
      const result = await req("POST", refreshUrl, {
        refreshToken: parsedAuthData.refreshToken,
      });

      if (!result.success) {
        console.log("Token refresh failed:", result.message);
        throw new Error(
          result.message || "Failed to refresh token. Please log in again."
        );
      }

      console.log("Token refresh successful");
      const {
        token,
        newRefreshToken,
        tokenExpiresAt,
        refreshTokenExpiresAt,
        ...user
      } = result;

      const authData = {
        token,
        refreshToken: newRefreshToken,
        user,
        isAuthenticated: true,
        tokenExpiresAt,
        refreshTokenExpiresAt,
      };

      localStorage.setItem(STORE, JSON.stringify(authData));
      setAuthState({
        token,
        refreshToken: newRefreshToken,
        user,
        isAuthenticated: true,
        loading: false,
      });
      return result;
    } catch (error) {
      console.error("Error in refreshAuthToken:", error);
      logout();
    }
  };

  useEffect(() => {
    if (!authState.isAuthenticated) return;

    const intervalTime = 10 * 60 * 1000;
    const interval = setInterval(checkAndRefreshToken, intervalTime);
    return () => clearInterval(interval);
  }, [authState.isAuthenticated]);

  const validateAndRedirect = useCallback((navigate) => {
    const storedAuthData = localStorage.getItem(STORE);
    if (storedAuthData && authState.isAuthenticated) {
      //console.log("authState.user!!!!!!!!!!", authState.user);
      const redirectUrl = ['admin', 'internal'].includes(authState.user?.accountType) 
        ? '/admin/dashboard' 
        : '/customer/home';
      navigate(redirectUrl);
    }
  }, [authState.isAuthenticated, authState.user]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout,
        refreshAuthToken,
        checkAndRefreshToken,
        validateAndRedirect,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
export { AuthContext };
export default AuthContext;
